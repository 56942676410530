import React, { useEffect, useState } from 'react';
import axios from 'axios';
import base_url from '../base_url';
import { useParams } from 'react-router-dom';

const ExpectedAppointmentsPage = () => {
  const [appointments, setAppointments] = useState([]);
  const [newAppointmentDate, setNewAppointmentDate] = useState('');
  const [newAppointmentTime, setNewAppointmentTime] = useState('');
  const [newAppointName,setNewAppointmentName] = useState('')
  const { userId } = useParams();


  const fetchExpectedAppointments = async () => {
   
    try {
      const response = await axios.get(`${base_url}/admin/apis/expected_appointments/user/${userId}`,{
        headers:{
          "authorization": localStorage.getItem('adminToken'),
        }
      });
      setAppointments(response.data.appointments);
    } catch (error) {
      console.error('Error fetching appointments', error);
    }
  };
  
  useEffect(() => {
    fetchExpectedAppointments();
  }, []);

  const handleAddExpectedAppointment = async () => {
      

    try {
      await axios.post(`${base_url}/admin/apis/expected_appointments`, {
        appointment_date: newAppointmentDate,
        appointment_time: newAppointmentTime,
        appointment_name:newAppointName,
        user_id:userId
      },{
        headers:{
          "authorization": localStorage.getItem('adminToken'),
        }
      });
      // Refresh appointments after adding
      setNewAppointmentDate('');
      setNewAppointmentTime('');
      fetchExpectedAppointments();
    } catch (error) {
      console.error('Error adding appointment', error);
    }
  };

  const handleDeleteAppointment = async (appointmentId) => {
    

    try {
      await axios.delete(`${base_url}/admin/apis/expected_appointments/${appointmentId}/${userId}`,{
        headers:{
          "authorization": localStorage.getItem('adminToken'),
        }
      });
      // Refresh appointments after deletion
      fetchExpectedAppointments();
    } catch (error) {
      console.error('Error deleting appointment', error);
    }
  };

  return (
    <div>
      <h1>ExpectedAppointments Page</h1>

      <div className="mb-3">
        <label htmlFor="appointmentDate" className="form-label">
          Appointment Name:
        </label>
        <input
          type="text"
          className="form-control"
          id="appointmentName"
          value={newAppointName}
          onChange={(e) => setNewAppointmentName(e.target.value)}
        />
      </div>


      <div className="mb-3">
        <label htmlFor="appointmentDate" className="form-label">
          Appointment Date:
        </label>
        <input
          type="date"
          className="form-control"
          id="appointmentDate"
          value={newAppointmentDate}
          onChange={(e) => setNewAppointmentDate(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="appointmentTime" className="form-label">
          Appointment Time:
        </label>
        <input
          type="time"
          className="form-control"
          id="appointmentTime"
          value={newAppointmentTime}
          onChange={(e) => setNewAppointmentTime(e.target.value)}
        />
      </div>
      <button className="btn btn-primary" onClick={handleAddExpectedAppointment}>
        Add Appointment
      </button>
      <ul className="list-group mt-3">
        {appointments.map((appointment) => (
          <li
            key={appointment.ex_appointment_id}
            className="list-group-item d-flex justify-content-between align-items-center"
          >
            {appointment.ex_appointment_name} {appointment.ex_appointment_date} - {appointment.ex_appointment_time}
            <button
              onClick={() => handleDeleteAppointment(appointment.ex_appointment_id)}
              className="btn btn-danger mx-2"
            >
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExpectedAppointmentsPage;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import base_url from '../base_url';

const DocumentsPage = () => {
  const { userId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [newDocumentName, setNewDocumentName] = useState('');

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(`${base_url}/admin/apis/documents/${userId}`,{
        headers:{
          "authorization": localStorage.getItem('adminToken'),
        }
      });
     
      setDocuments(response.data.documents);
    } catch (error) {
      console.error('Error fetching documents', error);
    }
  };

  


  const DeleteDocuments = async (id) => {
    try {
      const response = await axios.delete(`${base_url}/admin/apis/document/${id}`,{
        headers:{
          "authorization": localStorage.getItem('adminToken'),
        }
      });
     fetchDocuments()
    } catch (error) {
      alert("Something Went Wrong")
      console.error('Error fetching documents', error);
    }
  };

  
  useEffect(() => {
    fetchDocuments();
  }, [userId]);

  const handleAddDocument = async () => {
    try {
      await axios.post(`${base_url}/admin/apis/documents/${userId}`, { document_name: newDocumentName },{
        headers:{
          "authorization": localStorage.getItem('adminToken'),
        }
      });
      // Refresh documents after adding
      setNewDocumentName('');
      fetchDocuments();
    } catch (error) {
      alert("Something went wrong")
      console.error('Error adding document', error);
    }
  };

  return (
    <div>
      <h1>Documents Page</h1>
      <div className="mb-3">
        <label htmlFor="documentName" className="form-label">
          Document Name:
        </label>
        <input
          type="text"
          className="form-control"
          id="documentName"
          value={newDocumentName}
          onChange={(e) => setNewDocumentName(e.target.value)}
        />
      </div>
      <button className="btn btn-primary" onClick={handleAddDocument}>
        Add Document
      </button>
      <ul className="list-group mt-3">
        {documents.map((document,index) => (
          <li key={index} className="list-group-item">
            {document.document_name} {document.status == "uploaded"&&<a href={`${document.document_url}`}>Download File</a>}  <button className='btn btn-danger' onClick={()=>DeleteDocuments(document.document_id)} style={{float:'right'}}>Delete</button>    <b style={{backgroundColor:'skyblue',padding:5,color:'white',float:'right',marginLeft:10}}>{document.status}</b> 
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DocumentsPage;

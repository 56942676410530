import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import base_url from '../base_url';

export function Todos() {
  const [todos, setTodos] = useState([]);
  const [newTodoTitle, setNewTodoTitle] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const {userId} = useParams()

  const fetchTodos = async()=>{
    const token =  localStorage.getItem('adminToken');
        
    try {
 
      const response = await fetch(`${base_url}/admin/apis/todos?user_id=${userId}`, {
        method: 'GET',
        headers: {
          
          "authorization":token
        }
      });
      const result = await response.json();
     
      setTodos(result.todos)
      

    } catch (error) {
      alert("Something Went Wrong")
     console.log(error)
    }
  }


  const addTodo = async () => {

    if(newTodoTitle.length<1){
      alert("Todo Title is required")
      return false
    }
    const token = await localStorage.getItem('adminToken');

    
    try {
     
      const response = await fetch(`${base_url}/admin/apis/todos`, {
        method: 'POST',
        headers: {
          "authorization":token,

          'Content-Type': 'application/json'

        },
        body: JSON.stringify({title:newTodoTitle,userId:userId}),
      });
    
      if(response.status == 201){
        setModalVisible(false)
       fetchTodos()
      }
    } catch (error) {
      console.log(error)
      alert('Something Went Wrong');
    }
  };

  useEffect(()=>{
    fetchTodos()
  
  },[])

  const removeTodo = async(id) => {
    const token = await localStorage.getItem('adminToken');

        
    try {
 
      const response = await fetch(`${base_url}/admin/apis/todos/${id}/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "authorization":token
        }
      });
     
      setTodos((prevTodos) => prevTodos.filter((todo) => todo.todo_id !== id));

      

    } catch (error) {
      alert("Something Went Wrong")
     console.log(error)
    }
  };

  useEffect(() => {
    fetchTodos();
    // other useEffect logic remains the same
  }, []);

  return (
    <div className="container mt-5">
      <button className="btn btn-primary" onClick={() => setModalVisible(true)}>
        Add Todo
      </button>

      {/* Modal for adding new todo */}
      <div className={`modal ${modalVisible ? 'show' : ''}`} style={{ display: modalVisible ? 'block' : 'none' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Todo</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setModalVisible(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="todoTitle">Todo Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="todoTitle"
                    placeholder="Enter Todo Title"
                    value={newTodoTitle}
                    onChange={(e) => setNewTodoTitle(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setModalVisible(false)}>
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={addTodo}>
                Save Todo
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Displaying todos */}
      <div className="mt-3">
        {todos.map((todo) => (
          <div key={todo.todo_id} className="card mb-3 p-3">
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
              <h5 className="card-title">{todo.title}</h5>
              <button className="btn btn-danger "  onClick={() => removeTodo(todo.todo_id)}>
                Remove Todo
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

// src/pages/LoginPage.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import base_url from '../base_url';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${base_url}/admin/apis/admin/login`, { email, password });
     
      
        localStorage.setItem('adminToken', response.data.token);
        navigate('/users');
   
      
    } catch (error) {
      

      if (error.response) {
       
        if (error.response.status === 401) {
          alert("Invalid Email or Password");
        } else {
          alert("Something went wrong");
        }
      } else {
        
        alert("Something Went Wrong");
      }
    

    }
  }
    

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-4">
          <h2 className="mt-5 mb-3">Admin Login</h2>
          <form>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button type="button" className="btn btn-primary" onClick={handleLogin}>
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}


export default LoginPage

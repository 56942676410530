import React, { useState } from 'react';
import axios from 'axios';
import base_url from '../base_url';


const ChangeLogo = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select an image to upload.');
      return;
    }
    const jwtToken = localStorage.getItem('adminToken');

    const formData = new FormData();
    formData.append('logo', file);

    try {
      const response = await axios.post(`${base_url}/admin/apis/save_logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': jwtToken, 
        },
      });

      if (response.data.message) {
        alert(response.data.message);
         if(response.status == 200){
            window.location.reload()
         }   
      } else {
        alert('Failed to upload logo.');
      }
    } catch (error) {
      console.error('Error uploading logo:', error);
      alert('Error uploading logo. Please try again.');
    }
  };

  return (
    <div className="container mt-5">
        <img src={`${base_url}/static/logo/logo.png`} />
        <br />
      <h2>Upload Logo</h2>
      <div className="mb-3">
        <label htmlFor="logo" className="form-label">Select Image:</label>
        <input type="file" className="form-control" id="logo" onChange={handleFileChange} />
      </div>
      <button className="btn btn-primary" onClick={handleUpload}>Upload Image</button>
    </div>
  );
};

export default ChangeLogo;

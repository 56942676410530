import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import base_url from '../base_url';

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [newUser, setNewUser] = useState({
    email: '',
    name: '',
    password: '',
  });
  const current_user = useRef(null)
  const fetchUsers = async () => {
    try {
      // Get the JWT token from your authentication system (localStorage, cookies, etc.)
      const jwtToken = localStorage.getItem('adminToken');
     // Set the authorization header with the JWT token
     
      const response = await axios.get(`${base_url}/admin/apis/users`,{
        headers:{
          "authorization": jwtToken,
        }
      });
     
      setUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching users', error);
    }
  };



  const decode_user = async()=>{
    try {
      // Get the JWT token from your authentication system (localStorage, cookies, etc.)
      const jwtToken = localStorage.getItem('adminToken');
     // Set the authorization header with the JWT token
     
      const response = await axios.get(`${base_url}/admin/apis/decode_user`,{
        headers:{
          "authorization": jwtToken,
        }
      });
     
      
      if(response.data.user){
        current_user.current = response.data.user
      }else{
        current_user.current = null
      }
    } catch (error) {
      console.error('Error fetching users', error);
    }
  }


  useEffect(() => {
    decode_user()
    fetchUsers();
  }, []);

  const handleDelete = async (userId) => {
    try {
      const jwtToken = localStorage.getItem('adminToken');

      await axios.delete(`${base_url}/admin/apis/users/${userId}`,{
        headers:{
          "authorization": jwtToken,
        }
      });
      // Refresh users after deletion
      fetchUsers();
    } catch (error) {
      alert("Something Went Wrong")
      console.error('Error deleting user', error);
    }
  };

  const handleShowAddUserModal = () => {
    setShowAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
    setNewUser({
      email: '',
      name: '',
      password: '',
    });
  };

  const handleAddUser = async () => {
      // Get the JWT token from your authentication system (localStorage, cookies, etc.)
      const jwtToken = localStorage.getItem('adminToken');

    try {
      await axios.post(`${base_url}/admin/apis/users`,newUser,{
        headers:{
          "authorization": jwtToken,
        }
      });
      // Refresh users after adding
      fetchUsers();
      handleCloseAddUserModal();
    } catch (error) {
      alert("Something Went Wrong")
      console.error('Error adding user', error);
    }
  };

  return (
    <div>
      <h1>Users Page</h1>

      <div style={{flexDirection:'row',justifyContent:'space-between',display:'flex'}}>

      <button className="btn btn-primary mb-3 " onClick={handleShowAddUserModal}>
        Add User
      </button>

      <Link to={'/logo'} className="btn btn-primary mb-3 " >
        Logo
      </Link>

      <button className="btn btn-danger mb-3" onClick={()=>{
        localStorage.removeItem('adminToken')
        window.location = "/login"
      }
      }>
       Logout
      </button>
      </div>
      <br />

      <ul className="list-group">
        {users.map((user) => (
          <li key={user.id} className="list-group-item d-flex justify-content-between align-items-center">
            <div>
              <strong>{user.name}</strong> - {user.email}
            </div>
            <div>
            <Link to={`/todos/${user.id}`} className="btn btn-primary mx-2">
                Todos
              </Link>


              <Link to={`/chat/${user.id}`} className="btn btn-primary mx-2">
                Chat
              </Link>
              <Link to={`/documents/${user.id}`} className="btn btn-success mx-2">
                Documents
              </Link>

              <Link to={`/appointments/${user.id}`} className="btn btn-success mx-2">
                Appointments
              </Link>


              <Link to={`/expected_appointments/${user.id}`} className="btn btn-success mx-2">
                Expected Appointments
              </Link>

              <button onClick={() => handleDelete(user.id)} className="btn btn-danger mx-2">
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>


       {/* Add User Modal */}
       <div className={`modal ${showAddUserModal ? 'show' : ''}`} style={{ display: showAddUserModal ? 'block' : 'none' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add User</h5>
              <button type="button" className="btn-close" onClick={handleCloseAddUserModal}></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="newUserEmail" className="form-label">
                    Email:
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="newUserEmail"
                    value={newUser.email}
                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="newUserName" className="form-label">
                    Username:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="newUserName"
                    value={newUser.name}
                    onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="newUserPassword" className="form-label">
                    Password:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="newUserPassword"
                    value={newUser.password}
                    onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleCloseAddUserModal}>
                Close
              </button>
              <button type="button" className="btn btn-primary" onClick={handleAddUser}>
                Add User
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersPage;

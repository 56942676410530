// Chat.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {io} from 'socket.io-client';
import base_url from '../base_url';
import axios from 'axios';
import './style.css'
const socket = io(base_url); // Change the URL as needed

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const {userId} = useParams()
  const current_user = useRef(null)

  const decode_user = async()=>{
    try {
      // Get the JWT token from your authentication system (localStorage, cookies, etc.)
      const jwtToken = localStorage.getItem('adminToken');
     // Set the authorization header with the JWT token
     
      const response = await axios.get(`${base_url}/admin/apis/decode_user`,{
        headers:{
          "authorization": jwtToken,
        }
      });
     
      
      if(response.data.user){
        current_user.current = response.data.user
      }else{
        current_user.current = null
      }
    } catch (error) {
      console.error('Error fetching users', error);
    }
  }



  const fetchMessages = async () => {
   
    const response = await axios.get(`${base_url}/admin/apis/messages/${userId}`, {
      headers: {
        "authorization": localStorage.getItem('adminToken'),

      },
    });
   

    setMessages(response.data.messages);
  };


  useEffect(()=>{
   
    decode_user()
    fetchMessages()
  },[])

  useEffect(() => {
    socket.on('message', (data) => {
     fetchMessages()
    });
  }, [socket]);

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === '' || current_user.current == null) return;
   

    const senderId = current_user.current.id; 
    const receiverId = userId;
    
    // Emit the message to the server
    socket.emit('message', { sender_id: senderId, receiver_id: receiverId, message: newMessage });
    
    // Update the local state with the sent message
    
    setNewMessage('');
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-header">
          <h2 className="card-title">Chat Room</h2>
        </div>
        <div className="card-body chat-box">
          <ul className="list-unstyled">
            {messages.map((message, index) => (
              <li key={index} className={`message ${message.sender_id === current_user.current.id ? "sent" : "received"}`}>
                <strong>{message.sender_id == current_user.current.id?'Admin':"Employee"}</strong>: {message.message}
              </li>
            ))}
          </ul>
        </div>
        <div className="card-footer">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Type your message..."
              value={newMessage}
              onChange={handleInputChange}
            />
            <button className="btn btn-primary" onClick={handleSendMessage}>
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;

import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({children}) => {
  const isAuthenticated = localStorage.getItem('adminToken');
  if(!isAuthenticated){
        
    return <Navigate to={'/login'} />
}else{
    return children
}
 
};

export default PrivateRoute;

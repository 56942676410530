import React from 'react';
import {
 BrowserRouter as Router,
 Routes,
 Route,
 Navigate
} from "react-router-dom";
import LoginPage from './pages/LoginPage';
import UsersPage from './pages/UsersPage';
import ChatPage from './pages/ChatPage';
import AppointmentsPage from './pages/AppointmentsPage';
import DocumentsPage from './pages/DocumentsPage';

import PrivateRoute from './components/PrivateRoute';
import ChangeLogo from './pages/ChanageLogo';
import { Todos } from './pages/Todos';
import ExpectedAppointmentsPage from './pages/ExpectedAppointments';

function App() {
 return (
    <div className="container mt-3">
      <Router>
        <Routes>
        <Route path="/" exact Component={()=>{
        const admin = localStorage.getItem('adminToken');
        if(admin){
          console.log('Logged in')
          return <Navigate to="/users" />
        }else{
          return <Navigate to="/login" />
        }
          
        }} />


        <Route path="/login" exact element={<LoginPage />} />
        <Route path="/users" exact element={
        <PrivateRoute>
        <UsersPage />
        </PrivateRoute>} />
        <Route path="/chat/:userId" element={
        <PrivateRoute>
        <ChatPage />
        </PrivateRoute>} />
        <Route path="/appointments/:userId" element={
        <PrivateRoute>
        
        <AppointmentsPage />
        </PrivateRoute>
        } />

       <Route path="/expected_appointments/:userId" element={
        <PrivateRoute>
        <ExpectedAppointmentsPage />
        </PrivateRoute>
        } />

      <Route path="/todos/:userId" element={
        <PrivateRoute>
        
        <Todos />
        </PrivateRoute>
       } />


        <Route path="/documents/:userId" element={
        <PrivateRoute>
        
        <DocumentsPage />
        </PrivateRoute>} />

        <Route path="/logo" element={
        <PrivateRoute>
        
        <ChangeLogo />
        </PrivateRoute>} />
        
       
        </Routes>

      </Router>
    </div>
 );
}

export default App;